// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".adc19b0694ac71f2cb9a556ea2fea187-scss{padding:6% 8%;border-radius:20px}.ec810920286e64185a913c71d78fdbba-scss{display:grid;grid-gap:10px;grid-template-columns:repeat(2, 1fr)}.ec810920286e64185a913c71d78fdbba-scss ._219a06831abeeef4a0004e6dcf7458de-scss{display:flex;flex-direction:column;grid-column:1 / 3}.ec810920286e64185a913c71d78fdbba-scss ._75161e5541fecbb282cdb2dbd1ca9e06-scss{grid-column:1 / 2}._08d0ec91f57cda0b373e8b75b79ec302-scss{flex:1}._08d0ec91f57cda0b373e8b75b79ec302-scss ._960e009fe30153aa9e970c44f498cc8c-scss{padding:9;background:var(--searchBox-button-bg-color);line-height:initial;border-radius:25px;color:var(--white-light);width:75%;height:100%}._08d0ec91f57cda0b373e8b75b79ec302-scss .e8986bf600823d57b5d093115bd1eb0a-scss{display:inline-block;fill:var(--white-light);width:15px}._95b447883b46f830f02f208a73769e6e-scss{flex:2}.ea650b839e59cf8d5147846f71d0e108-scss{display:flex;padding:10px;justify-content:center;align-items:center}.ea650b839e59cf8d5147846f71d0e108-scss>div{padding:5px 10px}.ea650b839e59cf8d5147846f71d0e108-scss ._06405a9161b48dd65495d81aa85923e8-scss{background:var(--searchBox-button-bg-color);border-radius:25px;width:100%}.ea650b839e59cf8d5147846f71d0e108-scss ._3810d1f7cb27cc8be90e96b97b2ad1e4-scss{display:inline-block;fill:var(--white-light);width:15px;margin-right:5px}._65ca479f069a426f6eb3b65bb68efd6b-scss{background:var(--white-light);cursor:auto;padding:10px;border-style:none;border-radius:6px;width:100%}\n", ""]);
// Exports
exports.locals = {
	"searchContainer": "adc19b0694ac71f2cb9a556ea2fea187-scss",
	"container": "ec810920286e64185a913c71d78fdbba-scss",
	"inputs": "_219a06831abeeef4a0004e6dcf7458de-scss",
	"passengersInput": "_75161e5541fecbb282cdb2dbd1ca9e06-scss",
	"arrowButtonContainer": "_08d0ec91f57cda0b373e8b75b79ec302-scss",
	"arrowButton": "_960e009fe30153aa9e970c44f498cc8c-scss",
	"icon": "e8986bf600823d57b5d093115bd1eb0a-scss",
	"searchButtonContainer": "_95b447883b46f830f02f208a73769e6e-scss",
	"topButtonsContainer": "ea650b839e59cf8d5147846f71d0e108-scss",
	"topSearchButton": "_06405a9161b48dd65495d81aa85923e8-scss",
	"topMenuIcons": "_3810d1f7cb27cc8be90e96b97b2ad1e4-scss",
	"input": "_65ca479f069a426f6eb3b65bb68efd6b-scss"
};
module.exports = exports;
