import moment from 'moment';

const validateForm = trip => {
    const { departure, destination, departureDate, returnDate, passengers } = trip;

    if (!departure || !destination || !departureDate) {
        return false;
    }

    if (departure.id === destination.id) {
        return false;
    }

    if (returnDate && (returnDate < departureDate)) {
        return false;
    }

    if(passengers < 1 || passengers > 7){
        return false
    }

    return true;
}

const generateUrl = (trip, servicesText, seoText) => {
    const { departure, destination, departureDate, returnDate,
        adults, minors, infants } = trip;
    
    return `/${servicesText}/${seoText}/${departure.nameSeo}/${destination.nameSeo}/${departure.id}/${destination.id}/${moment(departureDate).format('DD-MM-YYYY')}/${returnDate ? moment(returnDate).format('DD-MM-YYYY') : '_'}/${adults}/${minors}/${infants}`;
}

export { validateForm, generateUrl }