import React, { useContext, useReducer, createContext, useEffect } from 'react';

import moment from 'moment';
import mapResults from './mapper';
import { groupServices, convertToJsDate } from 'shared/services/common';

// Hooks
import { useQueryString } from 'hooks/queryString';
import { useApplication } from 'hooks/application';
import useAxios from 'hooks/axios';

// Utils
import { getCurrentDateFormatted, validateSearchDates } from 'utils/searchDateValidation';

const ServiceContext = createContext();

const getDate = (formatDate, date) => {
  // Verificar si la fecha proporcionada es válida
  const isValidDate = moment(date, 'DD-MM-YYYY', true).isValid();

  // Si la fecha no es válida, se utiliza la fecha actual
  const tripDate = isValidDate ? moment(date, 'DD-MM-YYYY').format(formatDate) : getCurrentDateFormatted();
  return tripDate;
}

const useServices = () => {
  const [results, setResults] = useContext(ServiceContext);
  const { trip, departureService } = results;

  const setDepartureService = (departureService) =>
    setResults({ type: 'departureService', value: departureService });

  const setGroupedServices = (services) =>
    setResults({
      type: 'groupedServices',
      value: groupServices({ services, trip, departureService }),
    });

  const setServices = (groupedServices) =>
    setResults({ type: 'groupedServices', value: groupedServices });

  const setTrip = (trip) => setResults({ type: 'trip', value: trip });

  return {
    ...results,
    setDepartureService,
    setGroupedServices,
    setServices,
    setTrip,
    setResults,
  };
};

const searchServices = () => {
  const context = useServices();
  const axios = useAxios();
  const { getParam } = useQueryString();
  const { culture, country, idPv, activeCurrency } = useApplication();
  const { trip, departureService, setResults, fetchingData } = context;
  const {
    departure,
    destination,
    departureDate,
    returnDate,
    adults,
    minors,
    infants,
  } = trip;
  const oneWayTrip = !trip.returnDate;
  const currency = activeCurrency.code;

  const mapParams = (trip, idPv, country, lang) => {
    const { adults, minors, infants } = trip;

    return {
      IdPv: idPv,
      IdPais: country.id,
      pais: country.url,
      Adultos: adults,
      Menores: minors,
      Infantes: infants,
      Lang: lang,
    };
  };

  const departureSearch = async (trip, idPv, culture, country, departureDate, returnDate) => {
    const { formatDate, iso_code } = culture;
    const params = mapParams(trip, idPv, country, iso_code);
    params.IdPadOrigen = trip.departure.id;
    params.IdPadDestino = trip.destination.id;
    params.OrigenEsAgrupador = trip.departure.isAgrupador;
    params.DestinoEsAgrupador = trip.destination.isAgrupador;

    // Ajuste de la fecha de ida usando la validación
    const validatedDepartureDate = validateSearchDates(departureDate).departureDate;
    params.FechaIda = getDate(formatDate, validatedDepartureDate);

    // Verificar si la fecha de vuelta es válida
    const validatedReturnDate = validateSearchDates(departureDate, returnDate).returnDate;
    const isReturnDateValid = moment(validatedReturnDate, 'DD-MM-YYYY', true).isValid();

    // Si la fecha de vuelta no es válida, usar la fecha de ida válida para la búsqueda de vuelta
    params.FechaVuelta = isReturnDateValid ? getDate(formatDate, validatedReturnDate) : getDate(formatDate, validatedDepartureDate);

    const result = await search('ApiBusesBusqueda/Buscar', params);
    return { ...result, oneWayTrip: !returnDate, searching: false };
  };

  const destinationSearch = async (
    trip,
    idPv,
    culture,
    country,
    departureService,
    departureDate,
    returnDate
  ) => {
    const { formatDate, iso_code } = culture;
    const params = mapParams(trip, idPv, country, iso_code);
    const departureParam = trip.destination;
    const destinationParam = trip.departure;
    params.IdPadOrigen = departureParam.id;
    params.IdPadDestino = destinationParam.id;
    params.OrigenEsAgrupador = departureParam.isAgrupador;
    params.DestinoEsAgrupador = destinationParam.isAgrupador;
    params.FechaIda = getDate(formatDate, returnDate);
    params.FechaLlegadaServicioIda = getDate(
      formatDate,
      convertToJsDate(departureService.returnDate)
    );

    const result = await search('ApiBusesBusqueda/BuscarVuelta', params);
    delete result.departureName;
    delete result.destinationName;

    return { ...result, searching: false };
  };

  const search = async (method, params) => {
    try {
      const currentDate = getCurrentDateFormatted();
      setResults({ type: 'fetchingData', value: true });

      if (!validateSearchDates(departureDate, returnDate).isValid) {
          setResults({ type: 'departureDate', value: currentDate });
        if (returnDate) {
          setResults({ type: 'returnDate', value: currentDate });
        }
      }
      const res = await axios.get(method, { params });
      setResults({ type: 'fetchingData', value: false });

      return mapResults(res.data);
    } catch (error) {
      console.error('Error en la solicitud:', error);
    }
  };

  useEffect(() => {
    const tripValid = () => departure && destination && departureDate && validateSearchDates(departureDate, returnDate).isValid;
    const searchServices = async () => {
      setResults({ type: 'searching', value: true });

      if (tripValid()) {
        const result = !departureService
          ? await departureSearch(trip, idPv, culture, country, departureDate, returnDate)
          : await destinationSearch(
              trip,
              idPv,
              culture,
              country,
              departureService,
              departureDate,
              returnDate
            );

        const idgrupoeconomico = getParam('idgrupoeconomico');
        const idproveedor = getParam('idproveedor');

        if (idgrupoeconomico)
          result.services = result.services.filter(
            (x) => x.economicGroupId == idgrupoeconomico
          );
        if (idproveedor)
          result.services = result.services.filter(
            (x) => x.providerId == idproveedor
          );

        result.groupedServices = groupServices({
          services: result.services,
          trip,
          departureService,
        });

        setResults({ type: 'result', value: result });
      }
    };

    if (!fetchingData) {
      searchServices();
    }
  }, [
    departure,
    destination,
    departureDate,
    returnDate,
    departureService,
    adults,
    minors,
    infants,
  ]);

  return context;
};

const ServiceProvider = ({ children, value }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'result':
        return { ...state, ...action.value };
      default:
        return { ...state, [action.type]: action.value };
    }
  };

  const context = {
    trip: {},
    services: [],
    oneWayTrip: true,
    searching: true,
    fetchingData: false,
    ...value,
  };

  return (
    <ServiceContext.Provider value={useReducer(reducer, context)}>
      {children}
    </ServiceContext.Provider>
  );
};

export { ServiceProvider, useServices, searchServices, getDate };