export default {
    product: 'boats',
    searchTickets: 'Search Tickets',
    searchTicketsSeo: 'search-tickets',
    searchTicketsNow: 'Search your ticket',
    search:'Search',
    from:'From',
    to:'To',
    departure: 'Departure',
    return:'Return',
    errorMessageSameStops: 'Origin and Destination cannot be the same',
    filters:'Filters',
    passengers:'Passengers',
    inputPlaceholder: 'Enter city',
    datePlaceholder: 'Select date'
}