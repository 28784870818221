import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/shared/modal';

import urls from 'shared/urls';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import './translations';

export default () => {
  useStyles(classes);
  const { t } = useTranslation('processingModal');

  return (
    <Modal customClasses={{ modal: classes.modal }} hideHeader={true}>
      <img src={urls.getPublicPath('images/shared/procesando.gif')} alt='Procesando consulta' width='30' height='30' />
      <span className={classes.processing}>
        { t('processing') }
      </span>
    </Modal>
  );
};
