// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._202a7f86a296d0962a7de3a4a4bc5cf3-scss{display:inline-block;width:0;height:0;vertical-align:middle;border-top:4px solid;border-right:4px solid transparent;border-left:4px solid transparent;margin-left:4px;color:currentColor}\n", ""]);
// Exports
exports.locals = {
	"caret": "_202a7f86a296d0962a7de3a4a4bc5cf3-scss"
};
module.exports = exports;
