import React from 'react';

import Icon from 'components/shared/icon';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';

const Modal = ({ children, customClasses, hideHeader, title, onClose }) => {
    useStyles(classes, globalStyles);
    
    return (
        <div className={classes.container}>
            <div className={customClasses ? `${classes.modal} ${customClasses.modal}` : classes.modal}>
                {
                    !hideHeader &&
                        <div className={`${classes.header} ${globalStyles.flex}`}>
                            <div className={`${classes.title} ${globalStyles.flexAlignCenter}`}>
                                <span> { title } </span>
                            </div>
                            <div className={classes.iconContainer} onClick={onClose}>
                                <Icon classes={classes.icon} name='times-circle' /> 
                            </div>
                        </div>
                }
                { children }
            </div>
        </div>
    )
}

Modal.defaultProps = {
    hideHeader: false
};  

export default Modal;