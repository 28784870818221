import moment from 'moment';

const mapTrip = (stops, params, queryString = {}) => {
    const { departureName, destinationName, departurePadId, destinationPadId, departureDate, returnDate, adults, minors, infants } = params;

    const getPad = id => stops.length ? stops.find(stop => stop.id === id) : null;

    return {
      departureName,
      destinationName,
      departure: getPad(Number(departurePadId)),
      destination: getPad(Number(destinationPadId)),
      departureDate: departureDate
        ? moment(departureDate, 'DD-MM-YYYY')
        : null,
      returnDate: returnDate && returnDate !== '_'
        ? moment(returnDate, 'DD-MM-YYYY')
        : null,
      token: queryString.token,
      providerId: parseInt(queryString.providerId || 0, 10),
      economicGroupId:  parseInt(queryString.economicGroupId || 0, 10),
      adults: parseInt(adults || 1 , 10),
      minors: parseInt(minors || 0, 10),
      infants: parseInt(infants || 0, 10),
    }
}

export {
    mapTrip
}