export default [{
	id: 3928,
	nameSeo: "buenos-aires",
	text: "Puerto de Buenos Aires (Ciudad de Buenos Aires - Capital Federal)",
	countryId: 1
},
{
	id: 1413,
	nameSeo: "piriapolis",
	text: "Piriapolis (Uruguay)",
	countryId: 15
},
{
    id: 1446,
    nameSeo: "colonia-del-sacramento",
    text: "Colonia del Sacramento (Uruguay)",
    countryId: 15
},
{
	id: 1337,
	nameSeo: "montevideo",
	text: "Montevideo (Uruguay)",
	countryId: 15
},
{
	id: 1414,
	nameSeo: "punta-del-este",
	text: "Punta del Este (Uruguay)",
	countryId: 15
},
{
	id: 1377,
	nameSeo: "carmelo",
	text: "Carmelo (Uruguay)",
	countryId: 15
}];