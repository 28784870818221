import React from 'react';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';

export default ({ customClasses }) => {
    useStyles(classes);
    
    return (
        <span className={customClasses ? `${classes.caret} ${customClasses}`: classes.caret} />
    )
}
