// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._4a527164137b4a2a14ca7cae80f292f6-scss{position:fixed;left:0;right:0;top:0;bottom:0;background:rgba(0,0,0,0.5);z-index:100}._4a527164137b4a2a14ca7cae80f292f6-scss ._1595201c966bbfa34cca7b21b170a2f2-scss{background:var(--white-light);position:relative;display:flex;margin:auto;justify-content:center;align-items:center;z-index:10;border-color:var(--black-dark);box-shadow:-1px 5px 5px 0 rgba(0,0,0,0.75);padding:15px;box-sizing:border-box;margin-top:5%;border-radius:10px}._4a527164137b4a2a14ca7cae80f292f6-scss ._1595201c966bbfa34cca7b21b170a2f2-scss .ed2840f997bc20430947131b778c4c4b-scss{display:grid;grid-template-columns:1fr 35px;padding:10px 0;width:100%}._4a527164137b4a2a14ca7cae80f292f6-scss ._1595201c966bbfa34cca7b21b170a2f2-scss .ed2840f997bc20430947131b778c4c4b-scss ._18050ac5cdc45d561c473d64bde8c031-scss{flex:1;font-size:28px;font-weight:bold}._4a527164137b4a2a14ca7cae80f292f6-scss ._1595201c966bbfa34cca7b21b170a2f2-scss .ed2840f997bc20430947131b778c4c4b-scss ._9e3870250b974aeaf52081b511a73956-scss{display:flex;justify-content:flex-end;align-items:center;cursor:pointer}._4a527164137b4a2a14ca7cae80f292f6-scss ._1595201c966bbfa34cca7b21b170a2f2-scss .ed2840f997bc20430947131b778c4c4b-scss ._9e3870250b974aeaf52081b511a73956-scss .bbbfc7de9cb079eb1ab5746ee17b2239-scss{display:inline-block;fill:var(--black-dark);width:23px}\n", ""]);
// Exports
exports.locals = {
	"container": "_4a527164137b4a2a14ca7cae80f292f6-scss",
	"modal": "_1595201c966bbfa34cca7b21b170a2f2-scss",
	"header": "ed2840f997bc20430947131b778c4c4b-scss",
	"title": "_18050ac5cdc45d561c473d64bde8c031-scss",
	"iconContainer": "_9e3870250b974aeaf52081b511a73956-scss",
	"icon": "bbbfc7de9cb079eb1ab5746ee17b2239-scss"
};
module.exports = exports;
