import moment from 'moment';

const getCurrentDateFormatted = () => {
  return moment().format('DD-MM-YYYY');
};

const validateSearchDates = (departureDate, returnDate = null) => {
  const currentDate = moment().startOf('day');
  let departureDateMoment = moment(departureDate, 'DD-MM-YYYY');
  
  // Ajusta la fecha de salida si es anterior a la fecha actual.
  if (departureDateMoment.isBefore(currentDate, 'day')) {
    departureDateMoment = currentDate;
  }
  
  let returnDateMoment = returnDate ? moment(returnDate, 'DD-MM-YYYY') : null;
  if (returnDateMoment && returnDateMoment.isBefore(currentDate, 'day')) {
    returnDateMoment = currentDate;
  }
  
  const isValid = departureDateMoment.isSameOrAfter(currentDate, 'day') &&
                  (!returnDateMoment || returnDateMoment.isSameOrAfter(currentDate, 'day'));
  
  return {
    departureDate: departureDateMoment.format('DD-MM-YYYY'),
    returnDate: returnDateMoment ? returnDateMoment.format('DD-MM-YYYY') : null,
    currentDate: getCurrentDateFormatted(),
    isValid: isValid,
  };
};

export { validateSearchDates, getCurrentDateFormatted };