import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { 
	SearchLabel, SearchInput, SearchPicker, SearchButton, SearchContainer, ServicesSelectPassengers
} from 'components/shared/searchbox';
import Icon from 'components/shared/icon';
import Button from 'components/shared/button';
import Caret from 'components/shared/caret';

import { useServices } from 'hooks/services';
import { validateForm, generateUrl } from 'shared/ferries/searchbox';
import { errorCompare } from 'shared/searchBox/common';
import breakpoints from 'shared/breakpoints';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import moment from 'moment';
import './translations';

export default ({ stops, hasServices }) => {
	useStyles(classes, globalStyles);
	const history = useHistory();
	const { t } = useTranslation('boatsServicesSearchbox');
	const { trip, trip: { adults, minors, infants }, setDepartureService, setTrip} = useServices();
	const isMobile = useMediaQuery({ query: `(max-width:${breakpoints.sm})`});

	const [formValid, setFormValid] = useState(false);
	const [openForm, setOpenForm] = useState(!isMobile ? true : !hasServices);
	const [showPassengers, setShowPassengers] = useState(false);
	const [selectedTrip, setSelectedTrip] = useState(trip);
	const [filteredStops, setFilteredStops] = useState(stops);

	const { departure, destination, departureDate, returnDate } = selectedTrip;

	useEffect(() => { 
		setSelectedTrip(trip); 
	}, [trip])

	useEffect(() => {
		const valid = validateForm(selectedTrip);
		setFormValid(valid);
	}, [stops, selectedTrip]);

	const handlerItemSelected = (item, elem) => {
		const newTrip = { ...selectedTrip, [elem]: item };

		const filterItem = item || (newTrip.departure || newTrip.destination);

		const newStops = filterItem
			? stops.filter(stop => stop.countryId !== filterItem.countryId)
			: stops;

		setFilteredStops(newStops);
		setSelectedTrip(newTrip);
	}

	const handleSearch = event => {
		event.preventDefault();
		if(!selectedTrip.departureDate) selectedTrip.departureDate = moment();
		
		setTrip(selectedTrip);
		setDepartureService(null);

		const baseUrl = generateUrl(selectedTrip, t('product'), t('searchTicketsSeo'));
		history.push(baseUrl);
	}

	const handleSearchbox = () => setOpenForm(!openForm);
	const handlerPassengersChange = values => setSelectedTrip({ ...selectedTrip, ...values });

	const handlePassengersContainer = event => {
		setShowPassengers(!showPassengers);
		if(showPassengers) handleSearch(event);
	}

	return (
		<>
			{
				isMobile && !openForm &&
					<div className={classes.topButtonsContainer}>
						<Button 
							customClasses={classes.topSearchButton} 
							onClick={handleSearchbox}
						>
							<Icon classes={classes.topMenuIcons}  name='search' /> 
							{ t('search') }
							<Caret />
						</Button>
					</div>
			}
			{
				openForm &&
					<SearchContainer customClasses={{ root: classes.searchContainer }} onSubmit={handleSearch}>
						<div className={classes.container}>
							<div className={classes.inputs}>
								<SearchLabel 
									label={t('from')} 
									htmlFor='departure' 
									iconName='map-marker-alt'
								/>
								<SearchInput 
									characters={0} 
									field='departure' 
									id='departure' 
									placeholder={t('inputPlaceholder')}
									onSelectItem={handlerItemSelected} 
									stops={filteredStops} 
									initValue={departure} 
									disableUnderline 
								/>
							</div>

							<div className={classes.inputs}>
								<SearchLabel 
									label={t('to')} 
									htmlFor='destination' 
									iconName='map-marker-alt' 
								/>
								<SearchInput 
									characters={0} 
									field='destination' 
									id='destination'
									placeholder={t('inputPlaceholder')}
									onSelectItem={handlerItemSelected} 
									stops={filteredStops} 
									initValue={destination} 
									disableUnderline 
									err={errorCompare(selectedTrip, t('errorMessageSameStops'))} 
								/>
							</div>

							<div className={classes.inputs}>
								<SearchLabel 
									label={t('departure')} 
									htmlFor='departureDate' 
									iconName='calendar-alt' 
								/>
								<SearchPicker 
									id='departureDate' 
									field='departureDate' 
									placeholder={t('datePlaceholder')}
									onSelectItem={handlerItemSelected} 
									initValue={departureDate} 
									compareValue={destination} 
								/>
							</div>

							<div className={classes.inputs}>
								<SearchLabel 
									label={t('return')} 
									htmlFor='returnDate' 
									iconName='calendar-alt'
									optional={true} 
								/>
								<SearchPicker 
									id='returnDate' 
									field='returnDate' 
									placeholder={t('datePlaceholder')}
									onSelectItem={handlerItemSelected} 
									minDate={departureDate} 
									initValue={returnDate} 
								/>
							</div>
							<div className={`${classes.inputs} ${classes.passengersInput}`}>
								<SearchLabel 
									label={t('passengers')}
									htmlFor='passenger' 
									iconName='users'
								/>
								<input 
									readOnly
									value={(adults + minors + infants) || 1}
									type='passengers' 
									id='passengers' 
									className={classes.input} 
									onClick={handlePassengersContainer} 
								/>
							</div>
							{
								showPassengers && 
									<ServicesSelectPassengers 
										adults={adults}
										minors={minors}
										infants={infants}
										handleSearch={handleSearch}
										handlerPassengersChange={handlerPassengersChange} 
									/>
							}
						</div>
						<div className={globalStyles.flex}>
							{
								isMobile &&
									<div className={classes.arrowButtonContainer}>
										<Button 
											customClasses={classes.arrowButton} 
											onClick={handleSearchbox}
										>
											<Icon classes={classes.icon} name='arrow-up' />
										</Button>
									</div>
							}
							<div className={classes.searchButtonContainer}>
								<SearchButton data-testid='searchButton' disabled={!formValid} type='submit'>
									{ t('searchTickets') }
								</SearchButton>
							</div>
						</div>
					</SearchContainer>
			}
		</>
	);
}