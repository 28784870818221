import moment from 'moment';
import axios from 'axios';
import { getCurrentDateFormatted, validateSearchDates } from 'utils/searchDateValidation';

const groupServices = ({ services, trip, departureService }) => {
  const newServices = [[], []];
  services.forEach(service => {

        const intDate = parseInt(service.departureDate.replace(/[^0-9 +]/g, ''), 10);
        const jsDate = new Date(intDate);
        const jsDateString= jsDate.toLocaleDateString("es-AR",{timeZone:"America/Argentina/Buenos_Aires"});
        const tripString= departureService ? trip.returnDate._d.toLocaleDateString("es-AR",{timeZone:"America/Argentina/Buenos_Aires"}) : trip.departureDate._d.toLocaleDateString("es-AR",{timeZone:"America/Argentina/Buenos_Aires"})
        jsDateString ==tripString ? newServices[0].push(service) : newServices[1].push(service);
    });

    return newServices;
};

const convertToJsDate = date => {
    return new Date(parseInt(date.replace(/[^0-9 +]/g, ''), 10));
};

const getPadFromElastic = async id => {
  const { data } = await axios(`${process.env.STOPS_API_URL}stops/stops/_doc/${id}`);
  return data ? data.items : null;
}

const mapTrip = async(isElasticSearchEnabled, stops, params, queryString = {}) => {
    const { departureName, destinationName, departurePadId, destinationPadId, departureDate, returnDate, adults, minors, infants } = params;
    const departureDateAux = departureDate == null? getCurrentDateFormatted() : departureDate;
    const passengers = parseInt(adults || 1, 10) + parseInt(minors || 0, 10) + parseInt(infants || 0, 10)

    const getPad = async id =>  {
      return isElasticSearchEnabled 
        ? await getPadFromElastic(id)
        : stops.length ? stops.find(stop => stop.id === id) : null;
    }

    return {
      departureName,
      destinationName,
      departure: await getPad(Number(departurePadId)),
      destination: await getPad(Number(destinationPadId)),
      departureDate: departureDateAux
        ? moment(departureDateAux, 'DD-MM-YYYY')
        : null,
      returnDate: returnDate && returnDate !== '_'
        ? moment(returnDate, 'DD-MM-YYYY')
        : null,
      token: queryString.token,
      providerId: parseInt(queryString.providerId || 0, 10),
      economicGroupId:  parseInt(queryString.economicGroupId || 0, 10),
      adults: parseInt(adults || 1 , 10),
      minors: parseInt(minors || 0, 10),
      infants: parseInt(infants || 0, 10),
      passengers: passengers
    }
}

export {
    groupServices,
    convertToJsDate,
    mapTrip
}