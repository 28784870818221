import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryString = () => {
    const location = useLocation();

    const getParam = useCallback(param => {
        return new URLSearchParams(location?.search).get(param);
    }, [location]);

    const getQueryString = () => location?.search;

    return { getParam, getQueryString }
}
