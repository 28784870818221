export default {
    product: 'fluviales',
    searchTickets: 'Buscar Pasajes',
    searchTicketsSeo: 'buscar-pasajes',
    searchTicketsNow: 'Buscá tu pasaje en micro',
    search:'Buscar',
    from:'Origen',
    to:'Destino',
    departure: 'Partida',
    return:'Regreso',
    errorMessageSameStops: 'Origen y Destino no pueden ser iguales',
    filters:'Filtros',
    passengers: 'Pasajeros',
    inputPlaceholder: 'Ingrese ciudad',
    datePlaceholder: 'Seleccione fecha'
}