import { useState, useEffect } from 'react';

import WebWorker from 'shared/worker';
import urls from 'shared/urls';

const useStops = path => {
    const [stops, setStops] = useState([]);

    useEffect(() => {
        const worker = new Worker(WebWorker());
        worker.addEventListener("message", function (e) {
            setStops(e.data.result);
        });
        worker.postMessage({
            url: `${urls.domain}.ar/${path}`,
            mapper: {
                "id": "id",
                "nameSeo": "nombreSeo",
                "text": "txt"
            }
        });
    }, []);

    return stops;
}

export default useStops;