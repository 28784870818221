// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._41756c5b38ccce251564dd499ecee23c-scss{color:var(--grey-semi-dark);margin-top:10px;font-weight:bold}._6ce7b2beb4d3474de01662a13bf55a0a-scss{width:275px;height:100px;top:50px;padding:5px 0;flex-direction:column;font-size:1rem;font-weight:bold}\n", ""]);
// Exports
exports.locals = {
	"processing": "_41756c5b38ccce251564dd499ecee23c-scss",
	"modal": "_6ce7b2beb4d3474de01662a13bf55a0a-scss"
};
module.exports = exports;
