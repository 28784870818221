const mapResults = (result) => {
  const {
    NombrePadOrigenPresentacion,
    NombrePadDestinoPresentacion,
    Servicios,
    OtrasFechasBusqueda,
    SinDiasDeAnticipacion,
  } = result;

  return {
    departureName: NombrePadOrigenPresentacion,
    destinationName: NombrePadDestinoPresentacion,
    services: mapServices(Servicios),
    anotherDates: mapDates(OtrasFechasBusqueda),
    withoutAnticipationDays: SinDiasDeAnticipacion,
  };
};

const mapDates = (anotherDates) => {
  return anotherDates.map((date) => {
    const {
      Fecha,
      FechaPresentacion,
      FechaPresentacionDiaSemana,
      FechaSegunCultura,
    } = date;

    return {
      date: Fecha,
      displayedDate: FechaPresentacion,
      displayedDateWeekDay: FechaPresentacionDiaSemana,
      cultureDate: FechaSegunCultura,
    };
  });
};

const mapDuration = (duration) => ({
  hours: duration.Hours,
  minutes: duration.Minutes,
});

const mapRedirectionData = (redirectionData) => ({
  redirectable: redirectionData.EsRedireccionable,
  logoUrl: redirectionData.UrlLogo,
  websiteName: redirectionData.NombreDelSitio,
});

const mapServices = (services) => {
  return services.map((service, index) => {
    return {
      availablePurchase: service.CompraHabilitada,
      carrierProviderId: service.IdProveedorTransportista,
      providerId: service.IdProveedor,
      providerName: service.NombreProveedor,
      displayedProviderId: service.IdProveedorVisualizacion,
      displayedProviderName: service.NombreProveedorVisualizacion,
      carrierProviderName: service.NombreProveedorTransportista,
      displayedDepartureDateWeekDay: service.FechaSalidaPresentacionDia,
      displayedDepartureTime: service.FechaSalidaPresentacionHorario,
      displayedDestinationDateWeekDay: service.FechaLlegadaPresentacionDia,
      displayedDestinationTime: service.FechaLlegadaPresentacionHorario,
      departureName:service.PadOrigen,
      destinationName:service.PadDestino,
      quality: service.Calidad,
      displayedDuration: service.DuracionPresentacion,
      seats: service.Butacas,
      requestKey: service.ClaveSolicitud,
      serviceIndex: service.IndiceServicio,
      currencyCode: service.PrecioMoneda,
      displayedWholePart: service.PrecioParteEntera,
      displayedDecimalPart: service.PrecioParteDecimalPresentacion,
      departureDate: service.FechaSalida,
      departureDateByCulture: service.FechaSalidaSegunCultura,
      returnDateByCulture: service.FechaLlegadaSegunCultura,
      returnDate: service.FechaLlegada,
      price: service.Precio,
      economicGroupId: service.IdGrupoEconomico,
      departureFilterHour: service.HoraSalidaParaFiltro,
      duration: mapDuration(service.Duracion),
      position: index + 1,
      redirectionData: mapRedirectionData(service.DatosRedireccion),
      acceptsEticket: service.AceptaEticket,
      quotation: service.Cotizacion,
      acceptsBPE: service.AceptaBPE,
      uniqueKey: service.ClaveUnica
    };
  });
};

export default mapResults;
