export default {
    product: 'navios',
    searchTickets: 'Buscar Passagens',
    searchTicketsSeo: 'procurar-passagens',
    searchTicketsNow: 'Encontre sua Passagem de Ônibus',
    search:'Buscar',
    from:'Origem',
    to:'Destino',
    departure: 'Ida',
    return:'Volta',
    errorMessageSameStops: 'Origem e Destino não podem ser iguais',
    filters:'Filtros',
    passengers:'Passageiros',
    inputPlaceholder: 'Entre cidade',
    datePlaceholder: 'Selecione data'
}